@use '../shared' as *;
// @use 'general' as *;

html,
body {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: $color-gray-darkest;
  margin: 0;
}

.intro {
  color: $color-white;
  font-size: $type-lg;
  font-family: $font-default;
  font-weight: $default;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex-direction: column;
  z-index: $z-ultimate;
  height: 84vh;
  min-height: 84vh;

  @media (min-width: $break-md) {
    height: 100vh;
    min-height: 100vh;
  }

  b {
    color: $color-brand;
    padding: 0 6px 0 0;
  }

  &-logo {
    display: inline-flex;
    align-items: center;

    b {
      color: $color-white;
      font-weight: $regular;
    }

    i {
      background: linear-gradient(to bottom, $color-mid, $color-brand, $color-mid);
      font-weight: $regular;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: $type-md;
      animation: fadeInSlide .3s linear;
      
    }
  }

  &-fade {
    animation: fadeOut;
    animation-duration: 0.36s;
    animation-delay: 2.4s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-play-state: running;
    animation-iteration-count: 1;
  }
}

.animation01 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;

  div {
    width: 20%;
    height: 100%;
    animation: animation01 0.48s ease-in forwards;
    transform-origin: bottom;
    opacity: 0;

    &:nth-child(1) {
      background-color: $color-pink;
      animation-delay: 2.2s;
    }

    &:nth-child(2) {
      background-color: $color-orange;
      animation-delay: 2.1s;
    }

    &:nth-child(3) {
      background-color: $color-yellow;
      animation-delay: 2s;
    }

    &:nth-child(4) {
      background-color: $color-mid;
      animation-delay: 1.9s;
    }

    &:nth-child(5) {
      background-color: $color-brand;
      animation-delay: 1.8s;
    }
    &:nth-child(6) {
      background-color: $color-blue;
      animation-delay: 1.7s;
    }
  }
}

@keyframes animation01 {
  0% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale3d(0,1,1);
    opacity: 0;
  }
}

.rotating-square {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(0.1) rotate(0deg);
  animation: zoomInRotate .48s ease-out;
  background: $color-brand;
  animation-delay: 3.6s;
}

@keyframes zoomInRotate {
  0% {
    opacity: 0;
    transform: scale(100) rotate(0deg);
  }
  36% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(200) rotate(90deg);
  }
}
