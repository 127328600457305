@use "../shared" as *;

.pulse {
  animation: pulse;
  animation-duration: .5s;
  animation-timing-function: linear;
  animation-fill-mode:forwards;
  animation-direction: normal;
  animation-play-state: running;
  animation-iteration-count: infinite;
}

.fade-in {
  animation: fadeIn;
  animation-duration: .3s;
  animation-timing-function: linear;
  animation-fill-mode:forwards;
  animation-direction: normal;
  animation-play-state: running;
  animation-iteration-count: 1;
}


.fade-delay-long {
  animation: fadeIn;
  animation-duration: .3s;
  animation-timing-function: linear;
  animation-fill-mode:forwards;
  animation-direction: normal;
  animation-play-state: running;
  animation-iteration-count: 1;
  animation-delay: .6s;
  opacity: 0;
}

.fade-delay-short {
  animation: fadeIn;
  animation-duration: .3s;
  animation-timing-function: linear;
  animation-fill-mode:forwards;
  animation-direction: normal;
  animation-play-state: running;
  animation-iteration-count: 1;
  animation-delay: .3s;
  opacity: 0;
}

.pulse-turbo {
  animation: pulse;
  animation-duration: .1s;
  animation-timing-function: linear;
  animation-fill-mode:forwards;
  animation-direction: normal;
  animation-play-state: running;
  animation-iteration-count: 3;
}

.pulse-slow {
  animation: pulseSlow;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-fill-mode:forwards;
  animation-direction: normal;
  animation-play-state: running;
  animation-iteration-count: infinite;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeInSlide {
  0% {
    opacity: 0;
    transform: translateX(-24px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pulseSlow {
  0% {
    opacity: 1;
  }

  50% {
    opacity: .6;
  }

  100% {
    opacity: 1;
  }
}

@keyframes borderPulse {
  0% {
    border: 1px solid $color-crosshairs-note;
  }

  50% {
    border: 1px solid $color-crosshairs;
  }

  100% {
    border: 1px solid $color-crosshairs-note;
  }
}

@keyframes elastic {
  0% {
    width: 0;
  }

  50% {
    width: 100%;
  }

  100% {
    width: 0;
  }
}




.grow-right {
  animation: growRight;
  animation-duration: 0.6s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-direction: normal;
  animation-play-state: running;
  animation-iteration-count: 1;
  transform-origin: left;
  animation-delay: .3s;
  transform: scaleX(0);
  opacity: 0;
}

@keyframes growRight {
  0% {
    opacity: 0;
    transform: scaleX(0);
  }
  24% {
    opacity: 1;
  }
  
  100% {
    opacity: 1;
    transform: scaleX(1);
  }
}

@keyframes slideFromRight {
  from {
    opacity: 0;
    transform: translateX(120px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}


.spin {
  animation: spin 1s infinite linear;
  transform-origin: center center;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinHalf {
  0% {
    transform: rotate(-20deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
